<template>
  <section>
    <!-- <b-card class="card-browser-states">
      <b-card-body> -->
        <b-row class="ll-hr" :style="styleVar1">
          <b-col lg="3">
            <p class="l-pCon">{{totalInboundSKU}}</p>
            <p class="l-pTit">{{ $t('Total inbound SKU') }}</p>
          </b-col>
          <b-col lg="3">
            <p class="l-pCon">{{totalInboundQty}}</p>
            <p class="l-pTit">{{ $t('Total inbound qty') }}</p>
          </b-col>
          <b-col lg="3">
            <p class="l-pCon">{{totalWornQty}}</p>
            <p class="l-pTit">{{ $t('Total worn/damaged qty') }}</p>
          </b-col>
          <b-col lg="3">
            <p class="l-pCon">{{totalSaleableQty}}</p>
            <p class="l-pTit">{{ $t('Total saleable qty') }}</p>
          </b-col>
        </b-row>
        <b-table :items="item" :fields="fields"
        :borderless='false'
        :hover="true"
        class="ll-tableHei ll-table"
        :sticky-header="true"
        :style="styleVar">
          <template #cell(itemCode)="scope">
            <p class="ll-mulP">{{ scope.value }}</p>
            <p class="ll-mulP">{{ scope.item.itemDesc }}</p>
          </template>
        </b-table>
      <!-- </b-card-body>
    </b-card> -->
  </section>
</template>

<script>
import {
    BCard, BCardBody, BTable,
    BRow, BCol,
} from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BCardBody,
    BTable,
    BRow,
    BCol,
  },
  props: {
    heiVal: Number,
    details: Object,
  },
  data() {
    return {
      item: [],
      fields: [
        { key: 'itemCode', label: this.$t('Item') },
        { key: 'itemName', label: this.$t('Type') },
        { key: 'poNo', label: this.$t('Purchase order') },
        { key: 'plannedQty', label: this.$t('Planned/Act inbound') },
        { key: 'wornQty', label: this.$t('Worn qty') },
        { key: 'saleableQty', label: this.$t('Saleable qty') },
        { key: 'unit', label: this.$t('Unit Price') },
        { key: 'batchCode', label: this.$t('Batch No') },
        { key: 'expiryDate', label: this.$t('Expire date') },
      ],
      totalInboundSKU: '',
      totalInboundQty: '',
      totalWornQty: '',
      totalSaleableQty: '',
      tableHei: '',
      windowHeight: document.documentElement.clientHeight*0.67,
    }
  },
  computed: {
    styleVar() {
      return {
        '--height': this.windowHeight*0.6 + 'px'
      }
    },
    styleVar1() {
      return {
        '--height': this.windowHeight*0.1 + 'px'
      }
    },
  },
  watch: {
    details(val) {
      this.getInfo(val)
    },
    heiVal(val) {
      if (val < 627) {
        this.tableHei = (this.windowHeight*0.6) + 'px'
      } else {
        this.tableHei = (this.windowHeight*0.8) + 'px'
      }
    },
  },
  created() {},
  methods: {
    getInfo(info){
      this.item = info.detailLit
      this.totalInboundSKU = info.totalInboundSKU
      this.totalInboundQty = info.totalInboundQty
      this.totalWornQty = info.totalWornQty
      this.totalSaleableQty = info.totalSaleableQty
    },
    getDetail(oNo) {
      this.$http.get('asn/getDetail', { params: { orderNo: oNo } }).then(res => {
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          // console.log('res1', info.detailLit)
          this.item = info.detailLit
          this.totalInboundSKU = info.totalInboundSKU
          this.totalInboundQty = info.totalInboundQty
          this.totalWornQty = info.totalWornQty
          this.totalSaleableQty = info.totalSaleableQty
        }
      }).catch(error => console.log(error))
    },
  },
}
</script>
<style scoped>
.l-pCon{
  font-size: 14px;
  font-weight: bold;
  height: 30px;
  margin-bottom: 0;
}
/* .l-pTit{
  height: 36px;
} */
.ll-hr{
  border-bottom: 1px solid #d0d2d6;
  margin-bottom: 10px;
  height: var(--height1);
}
.ll-mulP{
  margin: 0;
}
.ll-tableHei{
  height: var(--height);
  max-height: unset;
}
</style>
