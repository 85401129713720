<template>
  <section>
    <b-row>
      <b-col lg="4" class="ll-spanBox">
        <span>{{ details.orderNo }}</span>
      </b-col>
      <b-col lg="4" class="ll-spanBox">
        <span>{{ latestOrderStatus }}</span>
      </b-col>
    </b-row>
    <b-row class="ll-hr">
      <b-col lg="4" class="ll-Sedfloor ll-line">
        <p class="ll-pTit">{{ $t('General') }}</p>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Customer') }}:</label>
          <span class="ll-span">{{customerName}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('ASN date') }}:</label>
          <span class="ll-span">{{asnInboundTime}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Total planned SKU') }}:</label>
          <span class="ll-span">{{totalPlannedSKU}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Total qty') }}:</label>
          <span class="ll-span">{{totalQty}}</span>
        </div>
      </b-col>
      <b-col lg="4" class="ll-Sedfloor">
        <p class="ll-pTit">{{ $t('Order worksheet') }}</p>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Warehouse') }}:</label>
          <span class="ll-span">{{warehouseCode}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Creation Date') }}:</label>
          <span class="ll-span">{{orderCreationDate}}</span>
        </div>
        <div class="ll-box">
          <label class="ll-lab">{{ $t('Order Type') }}:</label>
          <span class="ll-span">{{orderType}}</span>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <p class="ll-pTit ll-pt">{{ $t('Track & Trace') }}</p>
        <div class="ll-wrapper" v-if="initStatus">
          <app-timeline class="ll-apptime">
            <app-timeline-item
              :title="arrived"
              variant="info"
              class="ll-timeline"
              v-if="arrived !== null"
            >
              <div class="ll-sbox">
                <span class="ll-time">{{arrived}}</span>
                <span class="ll-desBox">{{ $t('Package Arrived Sort Center') }}</span>
              </div>
            </app-timeline-item>
            <app-timeline-item
              :title="createdAt"
              variant="info"
              class="ll-timeline"
              v-if="createdAt !== null"
            >
              <div class="ll-sbox">
                <span class="ll-time">{{createdAt}}</span>
                <span class="ll-desBox">{{ $t('Order Received') }}</span>
              </div>
            </app-timeline-item>
          </app-timeline>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BRow,
    BCol,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    details: Object,
  },
  data() {
    return {
      latestOrderStatus: '',
      customerName: '',
      asnInboundTime: '',
      totalPlannedSKU: '',
      totalQty: '',
      warehouseCode: '',
      orderCreationDate: '',
      orderType: '',
      createdAt: '',
      arrived: '',
      col1: '#6e6b7b',
      col2: '#6e6b7b',
      initStatus: false,
    }
  },
  computed: {},
  watch: {
    details(val) {
      this.getInfo(val)
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getInfo(info){
      this.customerName = info.customerName
      this.asnInboundTime = info.asnInboundTime
      this.totalPlannedSKU = info.totalPlannedSKU
      this.totalQty = info.totalQty
      this.warehouseCode = info.warehouseCode
      this.orderCreationDate = info.orderCreationDate
      this.orderType = info.orderType
      this.arrived = info.arrived
      this.createdAt = info.createdAt
      if (info.createdAt !== null) {
        this.initStatus = true
      }
    },
    getDetail(oNo) {
      this.$http.get('asn/getDetail', { params: { orderNo: oNo } }).then(res => {
        if (res.data.code === '10001') {
          this.$router.push({ name: 'auth-login' })
        } else if (res.data.code === '200') {
          const info = res.data.data
          // console.log('res1', info)
          this.customerName = info.customerName
          this.asnInboundTime = info.asnInboundTime
          this.totalPlannedSKU = info.totalPlannedSKU
          this.totalQty = info.totalQty
          this.warehouseCode = info.warehouseCode
          this.orderCreationDate = info.orderCreationDate
          this.orderType = info.orderType
          this.arrived = info.arrived
          this.createdAt = info.createdAt
          if (info.createdAt !== null) {
            this.initStatus = true
          }
        }
      }).catch(error => console.log(error))
    },
  },
}
</script>
<style scoped>
.ll-spanBox{
  margin: 10px 0;
}
.ll-spanBox span{
  font-size: 18px;
  font-weight: bold;
}
.ll-box{
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.ll-box .ll-lab{
  color: #6e6b7b;
}
.ll-pTit{
 color: #ed6d01;
  font-size: 14px;
}
.ll-line{
  border-right: 1px solid #d0d2d6;
}
.ll-p{
  font-weight: 500;
  color: #5e5873;
}
.ll-hr{
  border-bottom: 1px solid #d0d2d6;
}
.ll-Sedfloor{
  margin-bottom: 10px;
}
.ll-trackStatus{
  width: 80%;
  border: 1px solid #eee;
  height: 40px;
  display: flex;
}
.ll-colorSpan{
  display: inline-block;
  width: 2%;
  height: 39px;
  background: #4B7902;
}
.ll-desSpan{
  line-height: 40px;
  padding-left: 20px;
}
.ll-wrapper{
  display: flex;
}
.ll-wrap{
  width: 100%;
}
.ll-timeline{
  height: 58px;
}
.ll-tl{
 margin-bottom: 15px;
}
.ll-pt{
  margin: 20px 0;
}
.ll-sbox{
  display: flex;
}
.ll-time{
  width: 95px;
  white-space: break-spaces;
  display: block;
}
.ll-desBox{
  display: block;
  width: 80%;
  border: 1px solid #eee;
  padding: 10px;
  border-left: 5px solid #409eff;
  border-radius: 5px;
}
.ll-apptime{
  width: 80%;
}
</style>
